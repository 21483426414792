import React from 'react'
import { graphql } from 'gatsby'
import { Flex, Box } from 'rebass'
import styled from '@emotion/styled'
import htmlParser from 'html-react-parser'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import ContactCard from '../components/ContactCard'
import PageNav from '../components/PageNav'

const StyledContent = styled(Box)`
  min-height: 600px;
`

const PageTemplate = ({ data, pageContext, path }) => {
  const translations = data.translations.childTranslationsJson
  const content = data.content.childContentJson

  return (
    <Layout
      seo={{
        lang: pageContext.language,
        title: pageContext.title,
        description: translations.meta.description,
        shareImage: data.shareImage.childImageSharp.fixed
      }}
      translations={translations}
      apartments={content.apartments}
    >
      <Box>
        <PageHeader
          path={path}
          variant="small"
          paths={pageContext.paths}
          heroTitle={pageContext.title}
          title={translations.meta.title}
        />
      </Box>
      <Container>
        <PageNav pt={4} pb={0} />
        <StyledContent as="section" py={4}>
          <Flex mx={-2} flexDirection="row" flexWrap="wrap">
            <Box px={2} pb={4} width={[1, 1, 2 / 3]}>
              {htmlParser(data.pageContent.childHtml.html)}
            </Box>
            <Box px={2} width={[1, 1, 1 / 3]}>
              <ContactCard
                contactInfo={content}
                translations={translations}
              />
            </Box>
          </Flex>
        </StyledContent>
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($pathRegex: String!, $language: String!) {
    content: file(
      name: { eq: $language },
      absolutePath: {regex: "/content/"}
    ) {
      childContentJson {
        address
        phoneNumber
        emailAddress
        apartments {
          name
          path
          maxPeople
          roomCount
          pageTitle
        }
      }
    }

    pageContent: file(
      relativePath: { regex: $pathRegex },
      name: { eq: $language }
    ) {
      childHtml: childHtmlRehype {
        html
      }
    }

    shareImage: file(
      name: { eq: "share" }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    translations: file(
      name: { eq: $language }
      absolutePath: {regex: "/translations/"}
    ) {
      childTranslationsJson {
        ...CommonTranslationFields
      }
    }
  }
`
